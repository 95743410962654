import {
  Box,
  Circle,
  Flex,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FiCheck } from 'react-icons/fi';

import { panelHeadingProps } from './utils';

import AmeritasLogo from '../../../assets/images/logos/ameritas.svg';
import InspiraLogo from '../../../assets/images/logos/inspira.svg';
import CartaLogo from '../../../assets/images/logos/carta.svg';
import MercuryLogo from '../../../assets/images/logos/mercury.svg';
import BillLogo from '../../../assets/images/logos/bill.svg';
import BettermentLogo from '../../../assets/images/logos/betterment.svg';

const CheckmarkRow = ({ children }: { children: JSX.Element | string }) => {
  return (
    <Flex width="100%" alignItems="flex-start">
      <Circle size="32px" bg="#e4e7f4" mr="16px">
        <Icon as={FiCheck} color="primary.c500" />
      </Circle>
      <Text fontSize="16px" lineHeight="24px" color="gray.c900">
        {children}
      </Text>
    </Flex>
  );
};

const Developers = () => {
  const logos = [
    AmeritasLogo,
    InspiraLogo,
    CartaLogo,
    MercuryLogo,
    BillLogo,
    BettermentLogo,
  ];

  return (
    <>
      <Text align="center" fontSize="20px" lineHeight="24px" color="gray.c900">
        Build along some of the best
      </Text>
      <SimpleGrid mt="40px" columns={3} spacingX="48px" spacingY="40px">
        {logos.map((logo, i) => (
          <Image key={i} src={logo} />
        ))}
      </SimpleGrid>
    </>
  );
};

export const CallToActionPanel = () => {
  return (
    <Flex direction="column" justify="space-between" h="100%">
      <Box>
        <Heading {...panelHeadingProps} mb="44px">
          Connect to employment systems in minutes
        </Heading>
        <Spacer />
        <VStack spacing="32px">
          <CheckmarkRow>
            Sign up today and start building with our free Sandboxes
          </CheckmarkRow>
          <CheckmarkRow>
            Connect your first customer to sync data from their HR or 
            payroll system
          </CheckmarkRow>
          <CheckmarkRow>
            Unblock integrations so you can say &quot;yes&quot; to more
            customers (and revenue)
          </CheckmarkRow>
        </VStack>
      </Box>
      <Box mt="114px" mb="52px">
        <Developers />
      </Box>
    </Flex>
  );
};
