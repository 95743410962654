import {
  Box,
  Flex,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Tr,
} from '@chakra-ui/react';
import { TableWrapper } from './JobsTable';
import { Style } from './JobsTableComponents';
import { TableHeaderContent } from '../../components/TableHeaderContent';
import { COLORS } from '../../constant/colors';
import styled from '@emotion/styled';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import { EmptyReliusState } from '../../components/TableFilters/EmptyReliusState';
import { filterFns } from '../../components/TableFilters';
import { TextCell } from './TableCells';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import capitalize from 'lodash/capitalize';
import { DownloadFile } from './DownloadFile';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { useFlatfileRequests } from '../hooks/useFlatfileRequests';
import { useParams } from 'react-router-dom';
import chunk from 'lodash/chunk';
import { truncateUuidWithEllipsis } from '../../utils/truncateUuid';
import { useWindowWidth } from '@react-hook/window-size';
import orderBy from 'lodash/orderBy';

export type FlatfileRequestEntry = {
  id: string;
  fileLocation?: string | null;
  status: 'error' | 'success';
  deliveryDate: string;
  payPeriod: {
    startDate: string;
    endDate: string;
  };
};

const TableCellText = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableBodyContent = ({ rows }: { rows: Row<FlatfileRequestEntry>[] }) => {
  return (
    <>
      {rows.map((row) => {
        return (
          <Box
            as={Tr}
            width={'100%'}
            _hover={{ bg: COLORS.GRAY.GRAY_100 }}
            key={row.id}
          >
            {row.getVisibleCells().map((cell) => (
              <Td
                key={cell.id}
                style={cell.column.columnDef.meta?.style}
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                width="100%"
              >
                <TableCellText>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCellText>
              </Td>
            ))}
          </Box>
        );
      })}
    </>
  );
};

export const StatusBadge = ({ status }: { status: string }) => {
  return (
    <Flex
      direction={'row'}
      gap={'8px'}
      background={status === 'success' ? '#ECFDF3' : '#FFFAEB'}
      p={'8px'}
      borderRadius={'100px'}
      alignItems={'center'}
      width={'fit-content'}
    >
      <Box
        w={'8px'}
        height={'8px'}
        borderRadius={'100%'}
        bg={status === 'success' ? COLORS.GREEN : COLORS.ORANGE}
        minW={'8px'}
        minH={'8px'}
      />
      <Text
        fontSize={'14px'}
        fontWeight={'normal'}
        color={status === 'success' ? COLORS.GREEN : COLORS.ORANGE}
      >
        {capitalize(status)}
      </Text>
    </Flex>
  );
};

export const FlatfileRequestsTable = () => {
  const { connectionId, applicationId } = useParams<{
    connectionId: string;
    applicationId: string;
  }>();

  const { flatfileRequests = [] } = useFlatfileRequests({
    connectionId,
    applicationId,
  });

  const tableData = useMemo(
    () =>
      orderBy(
        flatfileRequests.map((e) => ({
          ...e,
          deliveryDate: e.createdAt,
          payPeriod: {
            startDate: e.requestParams.paymentStartDate,
            endDate: e.requestParams.paymentEndDate,
          },
        })),
        'createdAt',
        'desc',
      ),
    [flatfileRequests],
  );

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = chunk(tableData, 10).length;
  const currentTableData = useMemo(
    () =>
      chunk(tableData, 10).length > 0
        ? chunk(tableData, 10)[currentPage - 1]
        : [],
    [currentPage, tableData],
  );

  const columnHelper = createColumnHelper<FlatfileRequestEntry>();

  const ref = useRef<HTMLDivElement>(null);

  const [currentWidth, setCurrentWidth] = useState(0);

  const onlyWidth = useWindowWidth();

  useEffect(() => {
    setCurrentWidth(ref.current?.clientWidth ?? 0);
  }, [onlyWidth]);

  useEffect(() => {
    setCurrentWidth(ref.current?.clientWidth ?? 0);
  }, []);

  const columns = [
    columnHelper.accessor('id', {
      header: 'Filename',
      cell: (props) => {
        const td = tableData.find((e) => e.id === props.getValue());

        const fileLocation = td?.fileLocation;
        const fileDate = td?.deliveryDate;

        return (
          <div>
            <Box isTruncated>
              <Text fontSize={'14px'} fontWeight={'400'}>
                {truncateUuidWithEllipsis(
                  fileLocation ??
                    `${applicationId}/${connectionId}/${props.getValue()}/FINCH_PAYROLL_${moment(
                      fileDate,
                    ).format('MMDDYYYY')}.csv`.replaceAll(' ', ''),
                  currentWidth,
                )}
              </Text>
            </Box>
          </div>
        );
      },
      enableSorting: false,
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (props) => <StatusBadge status={props.getValue()} />,
      enableSorting: false,
    }),
    columnHelper.accessor('deliveryDate', {
      header: 'Delivery Date',
      cell: (props) => {
        const value = props.getValue();
        return (
          <TextCell text={value ? moment(value).format('MM/DD/YYYY') : ''} />
        );
      },
      enableSorting: false,
    }),
    columnHelper.accessor('payPeriod', {
      header: 'Pay Period',
      cell: (props) => {
        const value = props.getValue();
        return (
          <TextCell
            text={
              value
                ? `${moment(value.startDate).format('MM/DD/YYYY')} - ${moment(
                    value.endDate,
                  ).format('MM/DD/YYYY')}`
                : '-'
            }
          />
        );
      },
      enableSorting: false,
    }),
    columnHelper.accessor('id', {
      header: '',
      cell: (props) => (
        <Flex dir="row" alignItems={'center'} justifyContent={'flex-end'}>
          <DownloadFile
            id={props.getValue()}
            disabled={
              !tableData.find((e) => e.id === props.getValue())?.fileLocation
            }
          />
        </Flex>
      ),
      enableSorting: false,
    }),
  ];

  const { getRowModel, getHeaderGroups, getState } = useReactTable({
    data: currentTableData as FlatfileRequestEntry[],
    columns,
    filterFns,
    getCoreRowModel: getCoreRowModel(),
  });

  const rows = getRowModel().rows;
  const headerGroups = getHeaderGroups();

  const shouldShowEmptyRowsState = 0 === rows.length;

  return shouldShowEmptyRowsState ? (
    <TableWrapper>
      <EmptyReliusState table="jobs" />
    </TableWrapper>
  ) : (
    <TableWrapper>
      <TableContainer
        height="fit-content"
        style={{ overflow: 'visible', width: '100%' }}
        ref={(r) => {
          setCurrentWidth(r?.clientWidth ?? 0);
        }}
      >
        <Style>
          <Table variant="simple">
            <TableHeaderContent headerGroups={headerGroups} />
            <Tbody
              textColor={COLORS.FINCH.BLACK}
              borderTop={`1px solid ${COLORS.GRAY.GRAY_400}`}
            >
              <TableBodyContent rows={rows} />
            </Tbody>
            {totalPages > 0 ? (
              <Tfoot
                style={{
                  borderTop: `1px solid ${COLORS.GRAY.GRAY_400}`,
                  width: '100%',
                  columnSpan: 'all',
                }}
                justifyContent={'space-between'}
                alignItems={'flex-end'}
              >
                <Th></Th>
                <Th></Th>
                <Th width={'100%'}></Th>
                <Flex
                  dir="row"
                  justifyContent={'flex-end'}
                  alignItems={'flex-end'}
                  padding={'20px'}
                  width={'100%'}
                  position={'relative'}
                  left={'60px'}
                >
                  <HStack gap={'4px'}>
                    <Text
                      style={{
                        fontWeight: 600,
                        fontSize: '14px',
                        color: '#161616',
                      }}
                    >
                      {(currentPage - 1) * 10}-{currentPage * 10}
                    </Text>
                    <Text
                      style={{
                        fontWeight: 400,
                        fontSize: '14px',
                        color: '#737373',
                      }}
                    >
                      of
                    </Text>
                    <Text
                      style={{
                        fontWeight: 600,
                        fontSize: '14px',
                        color: '#161616',
                      }}
                    >
                      {tableData.length}
                    </Text>
                    <Flex>
                      <BsChevronLeft
                        strokeWidth={1}
                        color={COLORS.GRAY.GRAY_400}
                        width={'12px'}
                        height={'12px'}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        pointerEvents={currentPage === 1 ? 'none' : 'auto'}
                        cursor={currentPage === 1 ? 'not-allowed' : 'pointer'}
                      />
                      <BsChevronRight
                        strokeWidth={1}
                        color={COLORS.GRAY.GRAY_400}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        pointerEvents={
                          currentPage === totalPages ? 'none' : 'auto'
                        }
                        cursor={
                          currentPage === totalPages ? 'not-allowed' : 'pointer'
                        }
                      />
                    </Flex>
                  </HStack>
                </Flex>
              </Tfoot>
            ) : null}
          </Table>
        </Style>
      </TableContainer>
    </TableWrapper>
  );
};
