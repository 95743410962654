import { Box, Button, Stack, Text } from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { SearchIcon } from '../../shared/icons/SearchIcon';

const DocumentSVG = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="32" fill="#EFF1FF" />
      <path
        d="M25.3054 41.7221H38.6946V38.9446H25.3054V41.7221ZM25.3054 34.778H38.6946V32H25.3054V34.778ZM18.6667 48.6667V15.3334H35.9167L45.3334 24.75V48.6667H18.6667ZM34.5279 26.0555V18.1113H21.4446V45.8888H42.5554V26.0555H34.5279Z"
        fill="#3E4AE7"
      />
    </svg>
  );
};

export const EmptyReliusState = ({
  onClearFilters,
  height = '400px',
  table,
}: {
  table: 'jobs' | 'connections';
  height?: string;
  onClearFilters?: () => void;
}) => {
  return (
    <Stack
      height={height}
      spacing="24px"
      align="center"
      p="64px 100px"
      width={'100%'}
      textAlign="center"
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Box borderRadius="50%" bg="primary.50" p="2">
        <DocumentSVG />
      </Box>
      <Stack spacing="4px">
        <Text fontWeight="semibold">There&apos;s nothing here just yet</Text>
        <Text fontSize="sm">
          Your Relius file history will eventually show up here.
        </Text>
      </Stack>
      {onClearFilters && (
        <Button
          variant="outline"
          fontWeight="500"
          fontSize="sm"
          _hover={{ bg: COLORS.GRAY.GRAY_200 }}
          _active={{ bg: COLORS.GRAY.GRAY_200 }}
          borderColor={COLORS.GRAY.GRAY_400}
          onClick={onClearFilters}
        >
          View all {table}
        </Button>
      )}
    </Stack>
  );
};
