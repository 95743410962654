import { useQuery } from '@tanstack/react-query';
import { useConnectionDetailApi } from './useConnectionDetailApi';

export function useFlatfileRequests({
  applicationId,
  connectionId,
}: {
  applicationId: string;
  connectionId: string;
}) {
  const api = useConnectionDetailApi();
  const { isLoading, data } = useQuery({
    initialData: null,
    queryKey: ['flatfile-requests-get', applicationId, connectionId],
    queryFn: () => api.getFlatfileRequests(applicationId, connectionId),
  });

  return { flatfileRequests: data ?? [], isLoading };
}
