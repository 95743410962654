import { useState } from 'react';
import startCase from 'lodash/startCase';
import { COLORS } from '../../constant/colors';
import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Text,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Divider,
  Input,
} from '@chakra-ui/react';
import { ReliusSequenceIdWarningModal } from './ReliusSequenceIdWarningModal';
import { ReliusConfigurationSchema } from '../types';

const frequencies = {
  weekly: 'Weekly',
  biweekly: 'Biweekly',
  bimonthly: 'Bimonthly',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
} as const;

export function ReliusConfigurationModal({
  isOpen,
  isLoading,
  onClose,
  onSave,
  setIsOpen,
  planId: initialPlanId = '',
  isEnabled = false,
  sequenceIds: initialSequenceIds,
}: {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSave: (values: ReliusConfigurationSchema) => void;
  setIsOpen: (isOpen: boolean) => void;
  planId?: string;
  isEnabled?: boolean;
  sequenceIds: ReliusConfigurationSchema['sequenceIds'];
}) {
  const [isReliusIntegrationEnabled, setIsReliusIntegrationEnabled] =
    useState(isEnabled);
  const [planId, setPlanId] = useState(initialPlanId);
  const [sequenceIds, setSequenceIds] = useState<Record<string, string>>(
    initialSequenceIds.reduce((seqIds, payGroup) => {
      seqIds[payGroup.pay_group_id] = payGroup.sequence_id;

      return seqIds;
    }, Object.create(null)),
  );

  const [ensureSequenceIdOpen, setEnsureSequenceIdOpen] = useState(false);

  return (
    <>
      {ensureSequenceIdOpen && (
        <ReliusSequenceIdWarningModal
          isOpen={ensureSequenceIdOpen}
          onClose={() => {
            setEnsureSequenceIdOpen(false);
            setIsOpen(true);
          }}
          onSave={() => {
            setEnsureSequenceIdOpen(false);
            setIsOpen(false);
            onSave({
              enabled: isReliusIntegrationEnabled,
              plan_id: planId ?? '',
              sequenceIds: Object.entries(sequenceIds).map(([id, value]) => ({
                pay_group_id: id,
                frequency:
                  initialSequenceIds.find((pg) => pg.pay_group_id === id)
                    ?.frequency ?? '',
                sequence_id: parseInt(value),
              })),
            });
          }}
        />
      )}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent minW={'520px'} borderRadius="8px">
          <ModalHeader
            fontSize="16px"
            fontWeight="semibold"
            color={COLORS.FINCH.BLACK}
            style={{
              paddingBottom: '24px',
            }}
          >
            Configure Relius
          </ModalHeader>
          <ModalBody paddingBottom="24px" paddingTop={0}>
            <Stack>
              <Flex
                dir="row"
                alignItems={'start'}
                justifyContent={'space-between'}
              >
                <Stack gap="4px">
                  <Text fontSize={'14px'} fontWeight={500}>
                    Relius Integration
                  </Text>
                  <Text fontSize={'14px'} fontWeight={400} color={'#737373'}>
                    When turned on, Finch will start syncing ongoing payroll
                    data into Relius for this employer.
                  </Text>
                </Stack>
                <HStack>
                  <Text
                    fontSize={'14px'}
                    fontWeight={500}
                    color={
                      isReliusIntegrationEnabled
                        ? COLORS.GREEN
                        : COLORS.GRAY.GRAY_800
                    }
                  >
                    {isReliusIntegrationEnabled ? 'On' : 'Off'}
                  </Text>
                  <Switch
                    isChecked={isReliusIntegrationEnabled}
                    onChange={() =>
                      setIsReliusIntegrationEnabled(!isReliusIntegrationEnabled)
                    }
                  />
                </HStack>
              </Flex>
            </Stack>
          </ModalBody>
          <Divider />
          <ModalBody paddingTop="24px" paddingBottom="0px">
            <Stack gap="8px">
              <Stack gap="4px">
                <Text
                  fontSize={'14px'}
                  fontWeight={500}
                  color={COLORS.FINCH.BLACK}
                >
                  Plan ID{' '}
                  <Text as="span" fontSize="13px" fontWeight={400}>
                    (required)
                  </Text>
                </Text>
                <Text fontSize={'14px'} fontWeight={400} color={'#737373'}>
                  Input the employer&apos;s Plan ID as registered in Relius.
                </Text>
              </Stack>
              <Stack>
                <Input
                  placeholder="Enter Plan ID"
                  value={planId}
                  onChange={(e) => setPlanId(e.target.value)}
                  fontSize={'14px'}
                  padding={'12px'}
                />
              </Stack>
            </Stack>
            <Stack paddingTop="32px">
              <Stack gap="8px">
                <Stack gap="4px">
                  <Text
                    fontSize={'14px'}
                    fontWeight={500}
                    color={COLORS.FINCH.BLACK}
                  >
                    Sequence IDs{' '}
                    <Text as="span" fontSize="13px" fontWeight={400}>
                      (required)
                    </Text>
                  </Text>
                  <Text fontSize={'14px'} fontWeight={400} color={'#737373'}>
                    Sequence IDs can be found in the Payroll Data section in
                    Relius. Please ensure there is only one of each frequency in
                    Relius.
                  </Text>
                </Stack>
                {Object.entries(sequenceIds).map(([id, value]) => {
                  const pg = initialSequenceIds.find(
                    ({ pay_group_id }) => pay_group_id === id,
                  );

                  if (!pg) {
                    return;
                  }

                  return (
                    <HStack key={id}>
                      <Text
                        fontSize={'14px'}
                        fontWeight={500}
                        minWidth={'80px'}
                      >
                        {frequencies[
                          pg.frequency as keyof typeof frequencies
                        ] ?? startCase(pg.frequency)}
                      </Text>
                      <Input
                        placeholder="Sequence ID"
                        fontSize={'14px'}
                        padding={'12px'}
                        value={value}
                        onChange={(e) =>
                          setSequenceIds({
                            ...sequenceIds,
                            [pg?.pay_group_id]: e.target.value,
                          })
                        }
                      />
                    </HStack>
                  );
                })}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter w={'100%'} paddingTop="24px">
            <HStack
              w={'100%'}
              gap={2}
              alignItems={'center'}
              justifyContent={'flex-end'}
            >
              <Button
                onClick={onClose}
                variant="outline"
                border={'1px solid #D9D9D9'}
                _hover={{
                  backgroundColor: '#F4F4F4',
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onSave({
                    enabled: isReliusIntegrationEnabled,
                    plan_id: planId ?? '',
                    sequenceIds: Object.entries(sequenceIds).map(
                      ([id, value]) => ({
                        pay_group_id: id,
                        frequency:
                          initialSequenceIds.find(
                            (pg) => pg.pay_group_id === id,
                          )?.frequency ?? '',
                        sequence_id: parseInt(value),
                      }),
                    ),
                  });
                  onClose();
                }}
                disabled={isLoading || Object.entries(sequenceIds).length === 0}
                backgroundColor={COLORS.FINCH.PURPLE}
                _hover={{
                  backgroundColor: '#333dbd',
                }}
                color={COLORS.WHITE}
                alignItems="center"
              >
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
