import { Badge } from '@chakra-ui/react';
import { ApplicationStatus } from '@finch-api/developer-dashboard-common';

export enum ApplicationStatusBadgeSize {
  REGULAR,
  SMALL,
}

const badgeColorAndCopyFor = (applicationType?: ApplicationStatus) => {
  switch (applicationType) {
    case ApplicationStatus.SANDBOX:
      return { color: '#B54708', copy: 'Sandbox', backgroundColor: '#FFFAEB' };
    case ApplicationStatus.TEST:
      return {
        color: '#3E4AE7',
        copy: 'Development',
        backgroundColor: '#EFF1FF',
      };
    case ApplicationStatus.LIVE:
      return {
        backgroundColor: '#ECFDF3',
        copy: 'Production',
        color: '#027A48',
      };
    default:
      return;
  }
};

const badgeOptionsFor = (size: ApplicationStatusBadgeSize) => {
  const commonBadgeOptions = {
    fontFamily: 'Inter',
    fontWeight: '600',
  };

  switch (size) {
    case ApplicationStatusBadgeSize.SMALL:
      return {
        ...commonBadgeOptions,
        fontSize: 9,
      };
    case ApplicationStatusBadgeSize.REGULAR:
    default:
      return {
        ...commonBadgeOptions,
        px: 1.5,
        py: 1.5,
        borderRadius: 4,
        sx: {
          letterSpacing: '5%',
        },
      };
  }
};

export const ApplicationStatusBadge = ({
  applicationType,
  size = ApplicationStatusBadgeSize.REGULAR,
  style,
}: {
  applicationType?: ApplicationStatus;
  size?: ApplicationStatusBadgeSize;
  style?: React.CSSProperties;
}) => {
  const colorAndCopy = badgeColorAndCopyFor(applicationType);
  if (!colorAndCopy) return <></>;

  const badgeOptions = badgeOptionsFor(size);

  return (
    <Badge
      {...badgeOptions}
      style={{
        ...style,
        ...colorAndCopy,
        textTransform: 'capitalize',
      }}
    >
      {colorAndCopy.copy}
    </Badge>
  );
};
