import { useQuery } from '@tanstack/react-query';
import { RecordKeeper } from '../types';
import { useConnectionDetailApi } from './useConnectionDetailApi';

export function useConnectionConfiguration<T extends RecordKeeper>({
  applicationId,
  connectionId,
  thirdParty,
}: {
  applicationId: string;
  connectionId: string;
  thirdParty: T;
}) {
  const api = useConnectionDetailApi();
  const { isLoading, data } = useQuery({
    initialData: null,
    queryKey: [
      'connection-detail-configuration',
      applicationId,
      connectionId,
      thirdParty,
    ],
    queryFn: () =>
      api.getConfiguration<T>(applicationId, connectionId, thirdParty),
  });

  return { connectionConfiguration: data, isLoading };
}
