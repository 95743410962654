import type { ReadonlyDeep } from 'type-fest';
import {
  AccountStatus,
  ConnectionCategory,
} from '@finch-api/common/dist/external/dashboard/connection-status';
import {
  ExternalJobStatus,
  JobType,
} from '@finch-api/common/dist/external/dashboard/job-status';
import { ConnectStep } from 'types/connect';

export const jobTypeOptions = [
  {
    id: JobType.ORG_PAYROLL_SYNC,
    label: 'Data Sync',
  },
  {
    id: JobType.BENEFIT_CREATE,
    label: 'Create Benefit',
  },
  {
    id: JobType.BENEFIT_ENROLL,
    label: 'Enroll Benefits',
  },
  {
    id: JobType.BENEFIT_UNENROLL,
    label: 'Unenroll Benefits',
  },
  {
    id: JobType.BENEFIT_UPDATE,
    label: 'Update Benefits',
  },
];

export const connectionCategoryOptions = [
  {
    id: ConnectionCategory.Automated,
    label: 'Automated only',
  },
  {
    id: ConnectionCategory.Assisted,
    label: 'Assisted only',
  },
];

export const jobStatusOptions = [
  {
    id: ExternalJobStatus.COMPLETE,
    label: 'Success',
  },
  {
    id: ExternalJobStatus.PENDING,
    label: 'Pending',
  },
  {
    id: ExternalJobStatus.IN_PROGRESS,
    label: 'In Progress',
  },
  {
    id: ExternalJobStatus.REAUTH_ERROR,
    label: 'Reauth Error',
  },
  {
    id: ExternalJobStatus.PERMISSION_ERROR,
    label: 'Permission Error',
  },
  {
    id: ExternalJobStatus.PARTIAL_ERROR,
    label: 'Partial Error',
  },
];

export const PERMISSIONS_VERIFIED = 3;

export const ACCOUNTANT_INVITATION_ACCEPTED = 2;

export const TOKEN_CREATED = 1;

export const NO_PROGRESS = 0;

export type Progress =
  | typeof PERMISSIONS_VERIFIED
  | typeof ACCOUNTANT_INVITATION_ACCEPTED
  | typeof TOKEN_CREATED
  | typeof NO_PROGRESS;

export const assistedStatusProgress: ReadonlyDeep<
  Record<AccountStatus, Progress>
> = {
  [AccountStatus.CONNECTED]: PERMISSIONS_VERIFIED,
  [AccountStatus.REAUTH]: PERMISSIONS_VERIFIED,
  [AccountStatus.ERROR_PERMISSIONS]: ACCOUNTANT_INVITATION_ACCEPTED,
  [AccountStatus.ERROR_NO_ACCOUNT_SETUP]: TOKEN_CREATED,
  [AccountStatus.PENDING]: TOKEN_CREATED,
  [AccountStatus.DISCONNECTED]: NO_PROGRESS,
  [AccountStatus.PROCESSING]: NO_PROGRESS,
  [AccountStatus.UNKNOWN]: NO_PROGRESS,
};

export enum ConnectionDetailTab {
  OVERVIEW = 0,
  JOB_HISTORY = 1,
  PAY_STATEMENT_MAPPING = 2,
  ASSISTED_SETUP_STATUS = 3,
  API_EXPLORER = 4,
  AUTHENTICATION_PROGRESS = 5,
  CONNECT_SESSIONS = 6,
  RELIUS_INTEGRATION = 7,
}

export enum SessionHistoryTab {
  Details = 0,
  Events = 1,
}

export const connectionDetailTabs: Record<ConnectionDetailTab, string> = {
  [ConnectionDetailTab.OVERVIEW]: 'Overview',
  [ConnectionDetailTab.JOB_HISTORY]: 'Job History',
  [ConnectionDetailTab.PAY_STATEMENT_MAPPING]: 'Payroll Mapping',
  [ConnectionDetailTab.ASSISTED_SETUP_STATUS]: 'Assisted Setup Status',
  [ConnectionDetailTab.API_EXPLORER]: 'API Explorer',
  [ConnectionDetailTab.AUTHENTICATION_PROGRESS]: 'Authentication Progress',
  [ConnectionDetailTab.CONNECT_SESSIONS]: 'Session History',
  [ConnectionDetailTab.RELIUS_INTEGRATION]: 'Integrations',
};

export const ConnectStepMap: Record<ConnectStep, string> = {
  [ConnectStep.PERMISSIONS]: 'Permissions',
  [ConnectStep.SELECT_PROVIDER]: 'Provider Selection',
  [ConnectStep.PREAMBLE]: 'Terms of service',
  [ConnectStep.SIGN_IN]: 'Employer Sign In',
  [ConnectStep.MANUAL_SIGN_IN]: 'Assisted connect flow',
  [ConnectStep.MFA]: 'Multifactor Authentication',
  [ConnectStep.CAPTCHA]: 'Captcha',
  [ConnectStep.CHOOSE_ACCOUNT]: 'Choose Account',
};

export const RedirectUriMap = {
  'https://connect.tryfinch.com/complete-session': '—',
  'https://connect.tryfinch.com': '—',
};
