import {
  AccountStatus,
  ConnectionCategory,
} from '@finch-api/common/dist/external/dashboard/connection-status';
import { JobStatusEntry } from '@finch-api/common/dist/external/dashboard/job-status';
import { ImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import type { ReadonlyDeep } from 'type-fest';
import {
  Input,
  Item as _PayStatementItem,
  Label,
} from './pages/pay-statement-mapping/types';
import { z } from 'zod';

export type JobEntry = JobStatusEntry & {
  creationDate: Date | null;
};

export enum ProductGroup {
  Organization = 'Organization',
  Payroll = 'Payroll',
  Deductions = 'Deductions',
  Documents = 'Documents',
}

export type ConnectionProductGroup = {
  productGroup: ProductGroup;
  category: ConnectionCategory;
  scopes: string[];
  status: AccountStatus;
  reauthRequired: boolean;
};

export type ConnectionAccountDetail = {
  connectionAccountId: string;
  externalCompanyId: string | null;
  externalCompanyName: string | null;
  clientId: string;
  clientName: string;
  companyId: string;
  companyName: string;
  providerId: string;
  providerLogo: string;
  providerIcon: string;
  providerName: string;
  connectionStatus: AccountStatus;
  firstConnectedAt: string;
  statusTimeline: {
    createdAt: string;
    event: string;
    newStatus: string;
    connectionType: string;
    previousStatus: string | null;
  }[];
  lastRedactedTokensUsed: string[];
  products: {
    implementationKind: ImplementationKind;
    scopes: string[];
    status: AccountStatus;
    statusMessage: string;
  }[];
};

export type JobsTableFilterId = keyof Pick<
  JobEntry,
  'connectionCategory' | 'jobId' | 'type' | 'date' | 'status'
>;

type PermissionVerification = {
  title: 'Permission Verified';
  description: 'Employer has granted Finch access with sufficient permissions';
  completed: boolean;
};

type AccountantInvitation = {
  title: 'Account Invitation Accepted';
  description: 'Employer has invited Finch to access the payroll provider system';
  completed: boolean;
};

type TokenCreation = {
  title: 'Token Created';
  description: 'Employer has completed the Connect flow';
  completed: boolean;
};

/**
 * NOTE: the steps are defined in reverse order (from last to first)
 */
export type AssistedSetupStatusSteps = ReadonlyDeep<
  [PermissionVerification, AccountantInvitation, TokenCreation]
>;

export type PayStatementItem = _PayStatementItem[];

export type PayStatementItemLabel = Label;

export type PayStatementItemInput = Input;

export type ConnectionConfigurations = {
  pensionPro: PensionProConfiguration;
  relius: Pick<ReliusConfigurationSchema, 'enabled' | 'plan_id'>;
};

export type RecordKeeper = keyof ConnectionConfigurations;

export type ConnectionConfiguration =
  | PensionProConfiguration
  | Pick<ReliusConfigurationSchema, 'enabled' | 'plan_id'>;

export const ReliusConfiguration = z.object({
  sequenceIds: z.array(
    z.object({
      pay_group_id: z.string(),
      frequency: z.string(),
      sequence_id: z.number(),
    }),
  ),
  plan_id: z.string(),
  enabled: z.boolean(),
});

export type ReliusConfigurationSchema = z.infer<typeof ReliusConfiguration>;

export type PensionProConfiguration = {
  tenant_id: string;
  enabled: boolean;
  publish_to_pension_pro: boolean;
  publish_to_client: boolean;
  path_prefix: string;
  file_type: 'csv' | 'xlsx';
};

export const FlatfileRequest = z.object({
  id: z.string(),
  connectionAccountId: z.string(),
  triggeredBy: z.string(),
  triggerType: z.string(),
  status: z.string(),
  flatFileType: z.string(),
  deliveryMethod: z.string(),
  notificationType: z.string(),
  notificationEmail: z.string().nullish(),
  requestParams: z.record(z.string(), z.unknown()),
  fileLocation: z.string().nullish(),
  expiredAt: z.string().nullish(),
  createdAt: z.string(),
  updatedAt: z.string(),
  errorReason: z.string().nullish(),
  contentType: z.string().nullish(),
});

export type FlatfileRequestSchema = z.infer<typeof FlatfileRequest>;

export const PayGroup = z.object({
  companyId: z.string(),
  finchId: z.string(),
  frequencies: z.array(z.string()),
  lastModified: z.string(),
  name: z.string(),
  naturalId: z.string(),
});

export type PayGroupSchema = z.infer<typeof PayGroup>;

export type Features = z.infer<typeof featureSchema>;

export const featureSchema = z
  .object({
    connections: z
      .object({
        apiExplorer: z.boolean().default(false),
        bulkConnectURLs: z.boolean().default(false),
        flatFile: z.boolean().default(false),
        payrollMapping: z.boolean().default(false),
      })
      .default({}),
    isGustoTPA: z.boolean().default(false),
  })
  .default({});
