import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { OrganizationClient } from './organization-client';
import { Role } from '../constant/roles-and-permissions';

export const useUserRole = () => {
  const auth0 = useAuth0();
  const organizationClient = new OrganizationClient(auth0);

  const query = useQuery({
    queryKey: ['currentRole'],
    queryFn: () => organizationClient.getUserRole(auth0.user?.sub || ''),
    enabled: !!auth0.user,
    refetchOnMount: false,
  });

  return {
    ...query,
    isAdmin: query.data?.role === Role.Admin,
    role: query.data?.role,
    isLoading: auth0.isLoading || query.isLoading,
  };
};
