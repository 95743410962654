// Truncate UUIDs by keeping the first and last 6 characters
export const truncateUuid = (data: string) => {
  return data.length === 36 ? `${data.slice(0, 5)}...${data.slice(-5)}` : data;
};

export const truncateUuidWithEllipsis = (data: string, width: number) => {
  const optimizedWidth = width * 0.4;
  const sliceWidth = optimizedWidth / 15;

  return `${data.slice(0, sliceWidth)}...${data.slice(sliceWidth * -1)}`;
};
