import { useMutation } from '@tanstack/react-query';
import { useConnectionDetailApi } from './useConnectionDetailApi';
import { ReliusConfigurationSchema } from '../types';

export function useUpdateReliusConfiguration({
  applicationId,
  connectionId,
  configuration,
}: {
  applicationId: string;
  connectionId: string;
  configuration?: ReliusConfigurationSchema;
}) {
  const api = useConnectionDetailApi();
  const { isPending, data, mutateAsync, isError } = useMutation({
    mutationKey: [
      'update-relius-configuration',
      applicationId,
      connectionId,
      configuration,
    ],
    mutationFn: (configuration: ReliusConfigurationSchema) =>
      api.updateReliusConfiguration(applicationId, connectionId, configuration),
  });

  return {
    connectionConfiguration: data,
    isLoading: isPending,
    mutateAsync,
    isError,
  };
}
