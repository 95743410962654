import {
  ModalHeader,
  Heading,
  Tabs,
  TabPanel,
  TabPanels,
  Stack,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { FormProvider, UseFormReturn } from 'react-hook-form';
// import { DevTool } from '@hookform/devtools';

import { UseMutateFunction } from '@tanstack/react-query';
import {
  CreateConnectSessionError,
  CreateConnectSessionResponse,
  NewSession,
  ReauthSession,
} from 'connections-v2/hooks/useConnectSession';
import { useState } from 'react';
import { AppTab, AppTabList } from 'components/Tabs';
import { SingleLinkForm } from './SingleLinkForm';
import { EmployerCsvUploadForm } from './EmployerCsvUploadForm';
import {
  CreateBulkConnectSessionResponse,
  NewBulkSession,
} from 'connections-v2/hooks/useBulkConnectSessions';
import { useFeatures } from '../../../hooks/use-features';

export const CreateSessionForm = ({
  isPending,
  isPendingBulk,
  mutate,
  mutateBulk,
  initialRef,
  form,
}: {
  mutate: UseMutateFunction<
    CreateConnectSessionResponse,
    AxiosError<CreateConnectSessionError>,
    NewSession | ReauthSession,
    unknown
  >;
  mutateBulk: UseMutateFunction<
    CreateBulkConnectSessionResponse,
    AxiosError,
    NewBulkSession,
    unknown
  >;
  isPending: boolean;
  isPendingBulk: boolean;
  initialRef?: React.RefObject<HTMLInputElement>;
  form: UseFormReturn<NewSession>;
}) => {
  const [singleLinkFormSection, setSingleLinkFormSection] = useState<
    'required' | 'optional'
  >('required');

  const {
    connections: { bulkConnectURLs: enableBulkUploads },
  } = useFeatures();

  return (
    <FormProvider {...form}>
      <ModalHeader
        fontSize="20px"
        fontWeight="500"
        display="flex"
        justifyContent="center"
      >
        <Heading as="h1" size="md" fontWeight="medium" textAlign="center">
          Create a new Finch Connect Link{' '}
          {singleLinkFormSection === 'optional' && '(optional parameters)'}
        </Heading>
      </ModalHeader>
      <Stack px="6" flex="1">
        {enableBulkUploads ? (
          <Tabs display="flex" flexDirection="column" isFitted flex="1">
            <AppTabList>
              <AppTab>Single Link</AppTab>
              <AppTab isDisabled={singleLinkFormSection === 'optional'}>
                Bulk Links
              </AppTab>
            </AppTabList>
            <TabPanels display="flex" flexDirection="column" flex="1">
              <TabPanel display="flex" flexDirection="column" p="0" flex="1">
                <SingleLinkForm
                  singleLinkFormSection={singleLinkFormSection}
                  initialRef={initialRef}
                  form={form}
                  isPending={isPending}
                  setSingleLinkFormSection={setSingleLinkFormSection}
                  mutate={mutate}
                />
              </TabPanel>
              <TabPanel display="flex" flexDirection="column" p="0" flex="1">
                <EmployerCsvUploadForm
                  mutate={mutateBulk}
                  isPending={isPendingBulk}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          <SingleLinkForm
            singleLinkFormSection={singleLinkFormSection}
            initialRef={initialRef}
            form={form}
            isPending={isPending}
            setSingleLinkFormSection={setSingleLinkFormSection}
            mutate={mutate}
          />
        )}
      </Stack>

      {/* <DevTool control={form.control} /> */}
    </FormProvider>
  );
};
