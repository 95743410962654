import { useQuery } from '@tanstack/react-query';
import { useConnectionDetailApi } from './useConnectionDetailApi';
import { ReliusConfigurationSchema } from '../types';

export function useReliusSequenceIds({
  applicationId,
  connectionId,
}: {
  applicationId: string;
  connectionId: string;
}) {
  const api = useConnectionDetailApi();
  const { isPending, data, isError } = useQuery({
    queryKey: ['get-relius-configuration', applicationId, connectionId],
    queryFn: () => api.getReliusSequenceIds(applicationId, connectionId),
  });

  return {
    connectionConfiguration: data,
    isLoading: isPending,
    isError,
  };
}
