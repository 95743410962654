import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { COLORS } from '../../constant/colors';

export function ReliusSequenceIdWarningModal({
  isOpen,
  onClose,
  onSave,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent minW={'400px'} alignItems={'center'} gap={4}>
        <ModalHeader
          fontSize="18px"
          fontWeight="semibold"
          color={COLORS.FINCH.BLACK}
          style={{
            paddingBottom: '0px',
          }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          No Sequence ID Added
        </ModalHeader>
        <ModalBody paddingBottom={3}>
          <Stack>
            <Flex
              dir="row"
              alignItems={'start'}
              justifyContent={'space-between'}
            >
              <Stack alignItems={'center'}>
                <Text
                  fontSize={'14px'}
                  fontWeight={400}
                  color={'#161616'}
                  textAlign={'center'}
                >
                  While not necessary for this integration, we recommend adding
                  Sequence IDs. Data won&apos;t show up in Relius without them.
                  <br />
                  <br />
                  Are you sure you want to proceed?
                </Text>
              </Stack>
            </Flex>
          </Stack>
        </ModalBody>
        <ModalFooter w={'100%'} paddingTop={0}>
          <HStack
            w={'80%'}
            margin={'auto'}
            gap={4}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Button
              onClick={onClose}
              variant="outline"
              border={'1px solid #D9D9D9'}
              _hover={{
                backgroundColor: '#F4F4F4',
              }}
              width={'100%'}
            >
              Go back
            </Button>
            <Button
              onClick={() => {
                onSave();
              }}
              backgroundColor={COLORS.FINCH.PURPLE}
              _hover={{
                backgroundColor: '#333dbd',
              }}
              color={COLORS.WHITE}
              alignItems="center"
              width={'100%'}
            >
              Yes, save settings
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
