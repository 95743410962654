import { useParams } from 'react-router-dom';
import { useFlatfileRequests } from '../hooks/useFlatfileRequestDownload';

export function DownloadFile({
  id,
  disabled,
}: {
  id: string;
  disabled?: boolean;
}) {
  const { connectionId, applicationId } = useParams<{
    connectionId: string;
    applicationId: string;
  }>();

  const { refetch } = useFlatfileRequests({
    applicationId,
    connectionId,
    flatfileRequestId: id,
  });

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }}
      pointerEvents={disabled ? 'none' : 'auto'}
      opacity={disabled ? 0.5 : 1}
      onClick={async () => {
        const { data } = await refetch();

        const a = document.createElement('a');
        a.href = data.signedURL;
        a.download = data.signedURL;
        a.click();
      }}
    >
      <path
        d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM4 20V15H6V18H18V15H20V20H4Z"
        fill="#737373"
      />
    </svg>
  );
}
