import axios from 'axios';
import * as Sentry from '@sentry/react';

import {
  ConnectionAccountDetail,
  ConnectionConfiguration,
  ConnectionConfigurations,
  featureSchema,
  FlatfileRequestSchema,
  PayGroupSchema,
  PayStatementItem,
  PayStatementItemInput,
  PayStatementItemLabel,
  RecordKeeper,
  ReliusConfigurationSchema,
} from './types';
import type { Auth0ContextInterface, User } from '@auth0/auth0-react';
import { JobStatusEntry } from '@finch-api/common/dist/external/dashboard/job-status';

export class ConnectionDetailApi {
  constructor(
    private baseUrl: string,
    private auth: Auth0ContextInterface<User>,
  ) {}

  getToken = async () => {
    const { getAccessTokenSilently } = this.auth;
    return getAccessTokenSilently();
  };

  async getConnectionDetail(
    applicationId: string,
    connectionId: string,
  ): Promise<ConnectionAccountDetail> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.get<ConnectionAccountDetail>(
        uri.toString(),
        {
          headers: {
            Authorization: `Bearer ${await this.getToken()}`,
          },
        },
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async disconnect(applicationId: string, connectionId: string): Promise<void> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/disconnect`,
      this.baseUrl,
    );

    try {
      await axios.post(
        uri.toString(),
        {},
        {
          headers: {
            Authorization: `Bearer ${await this.getToken()}`,
          },
        },
      );
      return;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async jobs(
    applicationId: string,
    connectionId: string,
  ): Promise<JobStatusEntry[] | null> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/jobs`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.get(uri.toString(), {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
    return null;
  }

  async payStatementItem(
    applicationId: string,
    connectionId: string,
    options: { startDate?: Date } = {},
  ): Promise<PayStatementItem | null> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/pay-statement-item`,
      this.baseUrl,
    );

    for (const [key, value] of Object.entries(options)) {
      if (key === 'startDate') {
        const month = `${value.getMonth() + 1}`.padStart(2, '0');
        const day = `${value.getDate()}`.padStart(2, '0');

        uri.searchParams.set(key, `${value.getFullYear()}-${month}-${day}`);
      }
    }

    try {
      const { data } = await axios.get(uri.toString(), {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });

      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
    return null;
  }

  async payStatementItemLabels(
    applicationId: string,
    connectionId: string,
  ): Promise<PayStatementItemLabel[] | null> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/pay-statement-item/labels`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.get(uri.toString(), {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
    return null;
  }

  async createPayStatementLabels(
    applicationId: string,
    connectionId: string,
    labels: PayStatementItemInput[],
    code?: string,
  ): Promise<PayStatementItem | null> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/pay-statement-item${
        code ? `?code=${code}` : ''
      }`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.post(uri.toString(), labels, {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
    return null;
  }

  async fetchPayStatementMappingLink(
    applicationId: string,
    connectionId: string,
  ): Promise<any> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/pay-statement-item/link`,
      this.baseUrl,
    );

    return uri.toString();
  }

  async sendPayStatementMappingLink(
    applicationId: string,
    connectionId: string,
    recipient: string,
  ): Promise<any> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/pay-statement-item/link`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.post(
        uri.toString(),
        {
          recipient,
        },
        {
          headers: {
            Authorization: `Bearer ${await this.getToken()}`,
          },
        },
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async getConfiguration<T extends RecordKeeper>(
    applicationId: string,
    connectionId: string,
    thirdParty: T,
  ): Promise<ConnectionConfigurations[T] | null> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/configuration/${thirdParty}`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.get(uri.toString(), {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
    return null;
  }

  async getReliusSequenceIds(
    applicationId: string,
    connectionId: string,
  ): Promise<ReliusConfigurationSchema['sequenceIds'] | null> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connections/${connectionId}/pay-groups/sequence-ids`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.get(uri.toString(), {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
      return data?.sequenceIds;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }

    return null;
  }

  async updateReliusConfiguration(
    applicationId: string,
    connectionId: string,
    configuration: ReliusConfigurationSchema,
  ): Promise<ReliusConfigurationSchema | null> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/relius-configuration`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.patch(uri.toString(), configuration, {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }

    return null;
  }

  async getFlatfileRequests(
    applicationId: string,
    connectionId: string,
  ): Promise<FlatfileRequestSchema[] | null> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/flatfile-requests`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.get(uri.toString(), {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });

      return data.flatfileRequests;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }

    return null;
  }

  async getPayGroups(
    applicationId: string,
    connectionId: string,
  ): Promise<PayGroupSchema[] | null> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/pay-groups`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.get(uri.toString(), {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }

    return null;
  }

  async downloadFlatfileRequest(
    applicationId: string,
    connectionId: string,
    flatfileRequestId: string,
  ): Promise<any> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/flatfile-requests/${flatfileRequestId}/download`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.get(uri.toString(), {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }

    return null;
  }

  async getFeatures(applicationId?: string, connectionId?: string) {
    const uri = new URL(`/api/v1/features`, this.baseUrl);

    if (applicationId) {
      uri.searchParams.set('clientId', applicationId);
    }

    if (connectionId) {
      uri.searchParams.set('connectionAccountId', connectionId);
    }

    try {
      const { data } = await axios.get(uri.toString(), {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
      const validation = featureSchema.safeParse(data);

      if (!validation.success) {
        throw new Error('received invalid response from api');
      }

      return validation.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }

    return null;
  }
}
