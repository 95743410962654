import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useConnectionDetailApi } from '../connection-detail-v2/hooks/useConnectionDetailApi';
import { Features, featureSchema } from '../connection-detail-v2/types';

let _initial: Features;

function initial(): Features {
  if (_initial) {
    return _initial;
  }

  // HACK: using the zod schema to generate defaults
  const validation = featureSchema.safeParse({});

  if (!validation.success) {
    throw new Error('failed to generate default feature set');
  }

  _initial = validation.data;

  return _initial;
}

export function useFeatures(overrides?: {
  clientId?: string;
  connectionAccountId?: string;
}) {
  const queryParams = useParams<{
    applicationId?: string;
    connectionId?: string;
  }>();
  const clientId = queryParams.applicationId ?? overrides?.clientId;
  const connectionAccountId =
    queryParams.connectionId ?? overrides?.connectionAccountId;
  const api = useConnectionDetailApi();
  const { isPending, data, isError } = useQuery({
    queryKey: ['get-features', clientId, connectionAccountId].filter(Boolean),
    queryFn: () => api.getFeatures(clientId, connectionAccountId),
  });

  return { ...(data ?? initial()), isLoading: isPending, isError };
}
