import { Z_INDEX } from 'constant';
import {
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  ApplicationStatusBadge,
  ApplicationStatusBadgeSize,
} from '../credentials/components/ApplicationStatusBadge';
import { useOrganizationApplications } from './OrganizationApplicationsContext';

export const DropdownApplicationsMenu = () => {
  const history = useHistory();
  const params = useParams<{ applicationId: string }>();
  const location = useLocation();
  const { applicationId } = params;
  const applications = useOrganizationApplications();

  const remainingApplications = applications.filter(
    (application) =>
      applicationId !== application?.id && application?.id && application?.name,
  );
  const selectedApplication = applications.find(
    (application) => applicationId === application?.id && application?.name,
  );

  const hasRemainingApplications = (remainingApplications?.length ?? 0) > 0;

  const navigateToApplicationId = useCallback(
    (applicationId: string | string[]) => {
      const navigateTo = Array.isArray(applicationId)
        ? applicationId[0]
        : applicationId;

      if (navigateTo) {
        // Replace the current applicationId with the new one
        const newPath = location.pathname.replace(
          /\/applications\/[a-z0-9-]+/,
          `/applications/${navigateTo}`,
        );

        history.push(newPath);
      }
    },
    [history, location.pathname],
  );

  return (
    <Menu isLazy>
      <MenuButton
        as={Button}
        variant="secondary"
        cursor={hasRemainingApplications ? 'pointer' : 'unset'}
        width="300px"
        _hover={{ textDecoration: 'unset' }}
        color="gray.c600"
        rightIcon={hasRemainingApplications ? <FiChevronDown /> : undefined}
      >
        {/* Because we check during the find for name being defined, this will not be undefined.
          We're using Heading here due to one-off case for the text truncation. */}
        <Flex alignItems="center">
          <Text
            fontSize="14px"
            fontWeight="500"
            isTruncated
            style={{
              width: '100%',
            }}
          >
            {selectedApplication ? (
              <span
                style={{
                  width: '100%',
                  minWidth: '100px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <span
                  style={{
                    minWidth: '35px',
                    width: '100%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textAlign: 'left',
                  }}
                >
                  {selectedApplication?.name}
                </span>
                <Box
                  as="span"
                  ml={2}
                  style={{ position: 'relative', bottom: '1.5px', left: '0px' }}
                >
                  <ApplicationStatusBadge
                    applicationType={selectedApplication?.status}
                    size={ApplicationStatusBadgeSize.SMALL}
                    style={{
                      lineHeight: '8px',
                      padding: '4px 6px',
                      borderRadius: '4px',
                    }}
                  />
                </Box>
              </span>
            ) : (
              'Choose an application'
            )}
          </Text>
        </Flex>
      </MenuButton>
      {hasRemainingApplications && (
        <MenuList zIndex={Z_INDEX.MENU}>
          <MenuOptionGroup
            defaultValue={applicationId}
            type="radio"
            onChange={navigateToApplicationId}
          >
            {applications.map((application) => (
              <MenuItemOption key={application.id} value={application.id}>
                <HStack>
                  <>{application?.name}</>
                  <Box as="span" ml={2}>
                    <ApplicationStatusBadge
                      applicationType={application.status}
                      size={ApplicationStatusBadgeSize.SMALL}
                      style={{
                        lineHeight: '8px',
                        padding: '4px 6px',
                        borderRadius: '4px',
                      }}
                    />
                  </Box>
                </HStack>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      )}
    </Menu>
  );
};
