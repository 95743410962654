import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { ConnectionDetailScreen } from '../components/ConnectionDetailScreen';
import { PageContainer } from '../../components/PageContainer';
import { useConnectionConfiguration } from '../hooks/useConnectionConfiguration';
import { useFeatures } from '../../hooks/use-features';

export const ConnectionDetail = () => {
  const { applicationId, connectionId } = useParams<{
    connectionId: string;
    applicationId: string;
  }>();
  const features = useFeatures();

  return (
    <Box>
      <PageContainer>
        {features.isLoading ? null : (
          <ConnectionDetailScreen
            applicationId={applicationId}
            connectionId={connectionId}
            features={features}
          />
        )}
      </PageContainer>
    </Box>
  );
};
