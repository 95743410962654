import { Box, CloseButton, Flex, Image } from '@chakra-ui/react';
import { Z_INDEX } from '../constant';
import { Route, Switch } from 'react-router-dom';
import FinchLogo from '../assets/images/logos/finch/logo.svg';
import { ApplicationTabs } from './ApplicationTabs';
import { DropdownApplicationsMenu } from './DropdownApplicationsMenu';
import { useOrganizationApplications } from './OrganizationApplicationsContext';

const MENU_WIDTH = 350;

export const MobileNavMenu = ({
  onClose,
  show = false,
}: {
  onClose: () => void;
  show?: boolean;
}) => {
  const applications = useOrganizationApplications();

  return (
    <Flex
      position={'fixed'}
      top={0}
      left={0}
      w="full"
      h="full"
      zIndex={Z_INDEX.MENU}
      transition={'all .3s ease'}
      backgroundColor={show ? 'blackAlpha.500' : 'transparent'}
      onClick={onClose}
      pointerEvents={show ? 'auto' : 'none'}
    >
      <Flex
        alignItems={'flex-start'}
        position={'relative'}
        transition={'all .3s ease'}
        h="full"
        top={0}
        left={show ? 0 : `-${MENU_WIDTH}px`}
        w={`${MENU_WIDTH}px`}
        background="white"
        borderRight="1px solid"
        borderRightColor="gray.300"
        onClick={(e) => e.stopPropagation()}
        padding="32px 24px"
        flexDir={'column'}
        gap="32px"
      >
        <Flex justifyContent={'space-between'} w="full" align={'center'}>
          <Image src={FinchLogo} w="100px" />
          <CloseButton onClick={onClose} width="24px" height="24px" />
        </Flex>

        <Switch>
          <Route path="/app/applications/:applicationId">
            <DropdownApplicationsMenu />
          </Route>
        </Switch>

        <Box overflow="auto" w="full">
          <Switch>
            {applications && (
              <ApplicationTabs direction="column" onClick={onClose} />
            )}
          </Switch>
        </Box>
      </Flex>
    </Flex>
  );
};
