import { Button, Flex, Stack, Text, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { COLORS } from '../../constant/colors';
import { useConnectionConfiguration } from '../hooks/useConnectionConfiguration';
import { useReliusSequenceIds } from '../hooks/useGetReliusConfiguration';
import { useUpdateReliusConfiguration } from '../hooks/useUpdateReliusConfiguration';
import { FlatfileRequestsTable } from './FlatfileRequestsTable';
import { ReliusConfigurationModal } from './ReliusConfigurationModal';

export const ReliusSequenceMapping = () => {
  const [isReliusConfigurationModalOpen, setIsReliusConfigurationModalOpen] =
    useState(false);

  const { applicationId, connectionId } = useParams<{
    applicationId: string;
    connectionId: string;
  }>();

  const {
    mutateAsync: updateReliusConfiguration,
    isLoading,
    isError,
  } = useUpdateReliusConfiguration({
    applicationId,
    connectionId,
  });

  const toast = useToast();

  const { connectionConfiguration, isLoading: isConfigurationLoading } =
    useConnectionConfiguration({
      applicationId,
      connectionId,
      thirdParty: 'relius',
    });

  const { isLoading: isSeqIdsLoading, connectionConfiguration: sequenceIds } =
    useReliusSequenceIds({ applicationId, connectionId });

  return (
    <Stack w={'100%'} gap={4}>
      <Flex
        width={'100%'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Flex
          dir="row"
          alignItems={'center'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Text fontWeight={500} fontSize={'16px'}>
            Relius File History
          </Text>
          <Button
            variant={'outline'}
            borderRadius="lg"
            border="1px solid"
            borderColor={COLORS.GRAY.GRAY_400}
            background={COLORS.WHITE}
            padding="8px"
            _active={{ background: 'none', borderColor: COLORS.FINCH.PURPLE }}
            _hover={{ textDecoration: 'unset' }}
            color={COLORS.FINCH.BLACK}
            height="6"
            fontSize="13px"
            textAlign="left"
            onClick={() => setIsReliusConfigurationModalOpen(true)}
          >
            Configure Relius
          </Button>
        </Flex>
        {!(isLoading && isConfigurationLoading && isSeqIdsLoading) &&
        connectionConfiguration &&
        sequenceIds ? (
          <ReliusConfigurationModal
            isEnabled={connectionConfiguration.enabled}
            planId={connectionConfiguration.plan_id}
            sequenceIds={sequenceIds}
            isOpen={isReliusConfigurationModalOpen}
            isLoading={isLoading && isConfigurationLoading}
            onClose={() => setIsReliusConfigurationModalOpen(false)}
            onSave={async (values) => {
              await updateReliusConfiguration(values);

              if (!isError) {
                setIsReliusConfigurationModalOpen(false);
              } else {
                toast({
                  title: 'Error updating Relius configuration',
                  description: 'Please try again',
                  status: 'error',
                });
              }
            }}
            setIsOpen={setIsReliusConfigurationModalOpen}
          />
        ) : null}
      </Flex>
      <FlatfileRequestsTable />
    </Stack>
  );
};
