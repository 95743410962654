import { useQuery } from '@tanstack/react-query';
import { useConnectionDetailApi } from './useConnectionDetailApi';

export function useFlatfileRequests({
  applicationId,
  connectionId,
  flatfileRequestId,
}: {
  applicationId: string;
  connectionId: string;
  flatfileRequestId: string;
}) {
  const api = useConnectionDetailApi();
  const { isLoading, data, refetch } = useQuery({
    initialData: null,
    queryKey: [
      'flatfile-requests-download',
      applicationId,
      connectionId,
      flatfileRequestId,
    ],
    queryFn: () =>
      api.downloadFlatfileRequest(
        applicationId,
        connectionId,
        flatfileRequestId,
      ),
    enabled: false,
  });

  return { flatfileRequests: data, isLoading, refetch };
}
